// src/components/JobPostingUI.js

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import JobChatLayout from './JobChatLayout';
import ChatInput from './ChatInput';
import MessageBubble from './MessageBubble.js';
import axios from 'axios';


const JobPostingUI = ({onLogout}) => {
    const [jobs, setJobs] = useState([]);

    const [chatMessage, setChatMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const chatContainerRef = useRef(null);
    const navigate = useNavigate();


    useEffect(() => {
        navigate('/chatjob/all-job');

      }, []);
    

    const handleSendMessage = () => {
        if (chatMessage.trim()) {
            navigate('/chatjob/all-job');
            const newUserMessage = { id: Date.now(), text: chatMessage, sender: 'user' };
            setMessages(prevMessages => [...prevMessages, newUserMessage]);
            setChatMessage('');

            setTimeout(() => {
                const aiResponse = { id: Date.now() + 1, text: "I'm analyzing the top candidates for this job. Please give me a moment...", sender: 'ai' };
                setMessages(prevMessages => [...prevMessages, aiResponse]);
            }, 2000);
        }
    };

    return (
        <JobChatLayout jobs={jobs} selectedJobId={null} onLogout={onLogout}>
            <div ref={chatContainerRef} className="flex-1 p-4 overflow-y-auto">
                <div className="text-center py-8">
                    <h2 className="text-xl font-semibold mb-4">Select a Job you want to chat about</h2>
                    <div className="flex justify-center space-x-4">
                        {jobs.map(job => (
                            <button
                                key={job.id}
                                onClick={() => navigate(`/chatjob/${job.id}`)}
                                className="bg-gray-700 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition duration-300"
                            >
                                {job.title}
                            </button>
                        ))}
                    </div>
                </div>
                {messages.map(message => (
                    <MessageBubble key={message.id} message={message} />
                ))}
            </div>
            <ChatInput
                value={chatMessage}
                onChange={setChatMessage}
                onSend={handleSendMessage}
            />
        </JobChatLayout>
    );
};

export default JobPostingUI;