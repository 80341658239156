import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/';


const GoogleAuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    console.log("Codee", code)

    if (code) {
      // Send the code to your backend
      const  user_profile = localStorage.getItem('user_profile')
      const parsed_profile = JSON.parse(user_profile);
      axios.post(`${API_BASE_URL}api/google-auth-callback`, { "code": code, "user_id": parsed_profile['_id']})
        .then(response => {
          // Handle successful authentication
          console.log(response.data);
          localStorage.setItem('user_profile', JSON.stringify(response.data))
          navigate(`/profile/${parsed_profile['_id']}`)
          // Update your app state, redirect, etc.
        })
        .catch(error => {
          console.error('Error in Google auth callback:', error);
          navigate(`/profile/${parsed_profile['_id']}`)

        });
    }
  }, []);

  return <div>Processing Google authentication...</div>;
};

export default GoogleAuthCallback;