// src/components/ChatInput.js

import React, { useRef, useEffect } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';

const ChatInput = ({ value, onChange, onSend }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = `${Math.min(scrollHeight, 300)}px`;
        }
    }, [value]);

    return (
        <div className="w-full px-4 pb-4">
            <div className="w-1/2 mx-auto relative bg-gray-800 rounded-lg border border-gray-700 shadow-lg overflow-hidden">
                <textarea
                    ref={textareaRef}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            onSend();
                        }
                    }}
                    placeholder="Type your message here..."
                    className="w-full bg-transparent text-white py-3 px-4 pr-12 text-sm focus:outline-none resize-none overflow-y-auto"
                    style={{ minHeight: '40px', maxHeight: '300px' }}
                />
                <button
                    onClick={onSend}
                    className="absolute right-2 bottom-2 text-cyan-400 hover:text-cyan-300 p-1 transition-colors duration-200"
                >
                    <PaperAirplaneIcon className="h-5 w-5" />
                </button>
            </div>
        </div>
    );
};

export default ChatInput;