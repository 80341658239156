import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import JobPostingUI from './components/JobPostingUI';
import JobChatPage from './components/JobChatPage';
import CreateJobPage from './components/CreateJobPage';
import ApplyJobPage from './components/ApplyJobPage';
import LoginPage from './components/LoginPage';
import BlinkhireLandingPage from './components/BlinkHireLandingPage';
import JobDashboard from './components/JobDashboard';
import ChatScreen from './components/ChatScreen';
import CreateJob from './components/CreateJob';
import JobDetail from './components/JobDetail';
import CandidatePage from "./components/CandidatePage";
import ProfilePage from './components/ProfilePage';
import LinkedInCallback from './components/LinkedinCallback';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import GoogleAuthCallback from './components/GoogleAuthCallback';

const ProtectedRoute = ({ element, ...rest }) => {
  const token = localStorage.getItem('token');
  if (!token && rest.path !== '/apply/:jobId') {
    return <Navigate to="/login" replace />;
  }
  return element;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUser(token);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const fetchUser = async (token) => {
    try {
      const response = await fetch('http://localhost:8000/api/user', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
        setIsAuthenticated(true);
      } else {
        handleLogout();
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      handleLogout();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <GoogleOAuthProvider clientId="377342915801-rlk1456ksp4t5hmgbs14amrv2bdfb8ks.apps.googleusercontent.com">
      <Router>
        <Routes>
          <Route path="/" element={<BlinkhireLandingPage />} />
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/jobdashboard" replace />
              ) : (
                <LoginPage />
              )
            }
          />
          <Route
            path="/jobdashboard"
            element={<ProtectedRoute element={<JobDashboard onLogout={handleLogout} />} />}
          />
          <Route
            path="/profile/:id"
            element={<ProtectedRoute element={<ProfilePage />} />}
          />
          <Route
            path="/job-detail/:jobId"
            element={<ProtectedRoute element={<JobDetail />} />}
          />
          <Route
            path="/candidate/:resumeId"
            element={<ProtectedRoute element={<CandidatePage />} />}
          />
          <Route
            path="/chatjob"
            element={<ProtectedRoute element={<JobPostingUI onLogout={handleLogout} />} />}
          />
          <Route
            path="/chat/:jobId/:sessionId?"
            element={<ProtectedRoute element={<ChatScreen />} />}
          />
          <Route
            path="/chatjob/:jobId"
            element={<ProtectedRoute element={<JobChatPage onLogout={handleLogout} />} />}
          />
          <Route
            path="/create-job"
            element={<ProtectedRoute element={<CreateJob />} />}
          />
          <Route path="/apply/:jobId" element={<ApplyJobPage />} />

          <Route path="/linkedin-callback" element={<LinkedInCallback />} />
          <Route path="/google-callback" element={<GoogleAuthCallback />} />

          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />


        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;