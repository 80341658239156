import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import JobChatLayout from './JobChatLayout';
import ChatInput from './ChatInput';
import MessageBubble from './MessageBubble';

const JobChatPage = ({ onLogout }) => {
    const [job, setJob] = useState(null);
    const [chatMessage, setChatMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const chatContainerRef = useRef(null);
    const navigate = useNavigate();
    const { jobId } = useParams();
    const [userId, setUserId] = useState("")

    useEffect(() => {
        setMessages([]);
        const initializeChat = async () => {
            if (jobId === 'all-job') {
                setMessages([{ id: Date.now(), text: "Let's discuss all job positions. What would you like to know?", sender: 'ai' }]);
            } else {
                const jobData = await fetchJob(jobId);
                if (jobData && jobData.records && jobData.records.length > 0) {
                    setJob(jobData.records[0]);
                    setMessages([{ id: Date.now(), text: `Let's discuss the ${jobData.records[0].title} position. What would you like to know?`, sender: 'ai' }]);
                } else {
                    console.error('Invalid job data received');
                    navigate('/chatjob/all-job');
                }
            }
        };

      

        initializeChat();
    }, [jobId, navigate]);

    useEffect(() => {
        const user_profile = localStorage.get('user_profile')
        console.log(user_profile)
    }, []);

    

    const fetchJob = async (id) => {
        try {
            const response = await fetch(`http://localhost:8000/api/get_job_by_id/?job_id=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching job:', error);
            return null;
        }
    };

    const handleSendMessage = async () => {
        if (chatMessage.trim()) {
            const newUserMessage = { id: Date.now(), text: chatMessage, sender: 'user' };
            setMessages(prevMessages => [...prevMessages, newUserMessage]);
            setChatMessage('');
            setIsLoading(true);

            const sessionId = localStorage.getItem('sessionId') || 'default_session_id';
            const user_profile_str = localStorage.getItem("user_profile")
            const user_profile = JSON.parse(user_profile_str)
            console.log("User Profile: ", user_profile)
            const encodedMessage = encodeURIComponent(chatMessage);
            const url = `http://localhost:8001/agent_interaction?message=${encodedMessage}&session_id=${sessionId}&job_id=${jobId}&user_id=${user_profile['_id']}`;
            // message: str, session_id: str, job_id: str, session_title: str, job_title: str, user_id: str
            try {
                const eventSource = new EventSource(url);

                eventSource.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    console.log('Received data:', data);

                    if (data.type === 'response') {
                        setMessages(prevMessages => [...prevMessages, { id: Date.now(), text: data.content, sender: 'ai' }]);
                    } else if (data.type === 'python_code') {
                        // Handle Python code if needed
                        console.log('Received Python code:', data.content);
                    } else if (data.type === 'code_execution_results') {
                        // Handle code execution results if needed
                        console.log('Code execution results:', data.content);
                    } else if (data.type === 'complete') {
                        eventSource.close();
                        setIsLoading(false);
                    }
                };

                eventSource.onerror = (error) => {
                    console.error('EventSource failed:', error);
                    eventSource.close();
                    setIsLoading(false);
                };
            } catch (error) {
                console.error('Error sending message:', error);
                setIsLoading(false);
            }
        }
    };

    return (
        <JobChatLayout job={job} selectedJobId={jobId} onLogout={onLogout}>
            <div ref={chatContainerRef} className="flex-1 p-4 overflow-y-auto">
                {messages.map(message => (
                    <MessageBubble key={message.id} message={message} />
                ))}
                {isLoading && <div className="text-gray-500 italic">AI is typing...</div>}
            </div>
            <ChatInput
                value={chatMessage}
                onChange={setChatMessage}
                onSend={handleSendMessage}
                disabled={isLoading}
            />
        </JobChatLayout>
    );
};

export default JobChatPage;