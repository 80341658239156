import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { DocumentTextIcon, CloudArrowUpIcon, CurrencyDollarIcon, MapPinIcon, BriefcaseIcon } from '@heroicons/react/24/outline';

import ReactMarkdown from 'react-markdown';
import Spinner from './Spinner';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/';

const ApplyJobPage = () => {
    const { jobId } = useParams();
    const [job, setJob] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNumber: '',
    });
    const [location, setLocation] = useState(null);

    const fetchJob = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}api/jobs/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log("JSON DATA: ", data);
            return data;
        } catch (error) {
            console.error('Error fetching job:', error);
            return null;
        }
    };

    useEffect(() => {
        const initializeJob = async () => {
            const jobData = await fetchJob(jobId);
            if (jobData) {
                setJob(jobData);
                console.log("Selected job data in apply page: ", jobData);
            }
            setLoading(false);
        };
        initializeJob();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error("Error getting location:", error);
                }
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, [jobId]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            setPdfFile(file);
        } else {
            alert('Please upload a PDF file');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (pdfFile) {
            setLoading(true);
            setSuccessMessage('');
            setErrorMessage('');

            const submitFormData = new FormData();
            submitFormData.append('resume', pdfFile);
            submitFormData.append('name', formData.name);
            submitFormData.append('email', formData.email);
            submitFormData.append('contactNumber', formData.contactNumber);
            if (location) {
                submitFormData.append('latitude', location.latitude);
                submitFormData.append('longitude', location.longitude);
            }

            try {
                const response = await fetch(`${API_BASE_URL}apply/${jobId}`, {
                    method: 'POST',
                    body: submitFormData,
                });

                if (response.ok) {
                    const result = await response.json();
                    setSuccessMessage(result.message || 'Successfully applied for this job.');
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error('Error submitting application:', error);
                setErrorMessage('Failed to submit application. Please try again.');
            } finally {
                setLoading(false);
            }
        } else {
            alert('Please upload a PDF before applying');
        }
    };

    if (loading) return <div className="min-h-screen bg-gray-100 flex justify-center items-center"><Spinner /></div>;
    if (!job) return <div className="min-h-screen bg-gray-100 flex justify-center items-center text-gray-800">Job not found</div>;

    return (
        <div className="min-h-screen bg-gray-100 text-gray-800">
            <header className="bg-white shadow-sm">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
                    <Link to="/" className="text-2xl font-bold text-green-600">Blinkhire.ai</Link>
                </div>
            </header>
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <div class
Name="p-8">
                        <div className="flex items-center justify-between mb-6">
                            <h1 className="text-3xl font-bold text-gray-900">{job.title}</h1>
                            <div className="flex items-center space-x-4">
                                <img src={job.company_logo || "/placeholder-logo.png"} alt={`${job.company_name} logo`} className="w-16 h-16 object-contain" />
                                <span className="text-xl font-semibold text-gray-700">{job.company_name}</span>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                            <div className="flex items-center text-gray-600">
                                <MapPinIcon className="h-6 w-6 mr-2 text-green-600" />
                                <span className="text-lg">{job.location}</span>
                            </div>
                            <div className="flex items-center text-gray-600">
                                <CurrencyDollarIcon className="h-6 w-6 mr-2 text-green-600" />
                                <span className="text-lg">${job.salary_min.toLocaleString()} - ${job.salary_max.toLocaleString()}</span>
                            </div>
                            <div className="flex items-center text-gray-600">
                                <BriefcaseIcon className="h-6 w-6 mr-2 text-green-600" />
                                <span className="text-lg">{job.employment_type}</span>
                            </div>
                        </div>
                        <div className="bg-gray-50 rounded-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold mb-4 flex items-center text-gray-900">
                                <DocumentTextIcon className="h-7 w-7 mr-2 text-green-600" />
                                Job Description
                            </h2>
                            <ReactMarkdown className="text-gray-700 prose max-w-none">{job.description}</ReactMarkdown>
                        </div>
                        <form onSubmit={handleSubmit} className="bg-gray-50 rounded-lg p-6">
                            <h2 className="text-2xl font-semibold mb-6 flex items-center text-gray-900">
                                <CloudArrowUpIcon className="h-7 w-7 mr-2 text-green-600" />
                                Apply for this Job
                            </h2>
                            {loading && <Spinner />}
                            {successMessage && <p className="text-green-600 text-lg font-semibold mb-4 p-4 bg-green-100 rounded-md">{successMessage}</p>}
                            {errorMessage && <p className="text-red-600 text-lg font-semibold mb-4 p-4 bg-red-100 rounded-md">{errorMessage}</p>}
                            {!loading && !successMessage && !errorMessage && (
                                <div className="space-y-6">
                                    <div>
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Full Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                                            placeholder="John Doe"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                                            placeholder="johndoe@example.com"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="contactNumber" className="block text-sm font-medium text-gray-700 mb-1">Contact Number</label>
                                        <input
                                            type="tel"
                                            id="contactNumber"
                                            name="contactNumber"
                                            value={formData.contactNumber}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                                            placeholder="+1 (555) 123-4567"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="pdf-upload" className="block text-sm font-medium text-gray-700 mb-1">Resume (PDF)</label>
                                        <input
                                            type="file"
                                            accept=".pdf"
                                            onChange={handleFileChange}
                                            className="w-full text-sm text-gray-500
                                                file:mr-4 file:py-2 file:px-4
                                                file:rounded-md file:border-0
                                                file:text-sm file:font-semibold
                                                file:bg-green-50 file:text-green-700
                                                hover:file:bg-green-100"
                                            id="pdf-upload"
                                            required
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-4 rounded-md transition duration-300 shadow-md hover:shadow-lg"
                                    >
                                        Submit Application
                                    </button>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ApplyJobPage;