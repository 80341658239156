import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/';

const LinkedInCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleLinkedInAuth = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const receivedState = params.get('state');

      // Retrieve the stored state
      const storedState = localStorage.getItem('linkedInAuthState');
      
      // Clear the stored state immediately
      localStorage.removeItem('linkedInAuthState');

      // if (!receivedState || receivedState !== storedState) {
      //   setError('Invalid state parameter. Possible CSRF attack.');
      //   setTimeout(() => navigate('/login', { state: { error: 'Authentication failed. Please try again.' } }), 5000);
      //   return;
      // }

      if (!code) {
        setError('No authorization code received from LinkedIn.');
        setTimeout(() => navigate('/login', { state: { error: 'No authorization code received from LinkedIn.' } }), 5000);
        return;
      }

      try {
        const { mode } = JSON.parse(atob(receivedState));
        const endpoint = mode === 'signup' ? 'api/linkedin-signup' : 'api/linkedin-login';

        const response = await axios.post(`${API_BASE_URL}${endpoint}`, { code });

        if (mode === 'signup') {
          // For signup, redirect to login without storing anything
          navigate('/login');
        } else {
          // For login, store token and user profile
          const { access_token, user_profile } = response.data;
          
          localStorage.setItem('token', access_token);
          localStorage.setItem('user_profile', JSON.stringify(user_profile));
          
          navigate('/jobdashboard');
        }

      } catch (error) {
        let errorMessage = 'Failed to complete LinkedIn authentication.';
        if (error.response) {
          errorMessage += ` Server responded with: ${error.response.data.detail || error.response.statusText}`;
        } else if (error.request) {
          errorMessage += ' No response received from the server.';
        } else {
          errorMessage += ` Error: ${error.message}`;
        }
        console.error('Error during LinkedIn authentication:', error);
        setError(errorMessage);
        setTimeout(() => navigate('/login', { state: { error: errorMessage } }), 5000);
      }
    };

    handleLinkedInAuth();
  }, [location, navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Authentication Error</h2>
          <p className="text-gray-700 mb-4">{error}</p>
          <p className="text-sm text-gray-500">Redirecting to login page in 5 seconds...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-2xl font-bold text-green-600 mb-4">Processing LinkedIn Login</h2>
        <p className="text-gray-700">Please wait while we complete your authentication...</p>
      </div>
    </div>
  );
};

export default LinkedInCallback;