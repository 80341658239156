// src/components/Spinner.js
import React from 'react';

const Spinner = () => (
    <div className="flex justify-center items-center h-64">
        <div className="w-12 h-12 border-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
    </div>
);

export default Spinner;
