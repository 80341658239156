
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { DollarSign, MapPin, Upload, X, AlertCircle, User, Briefcase } from 'lucide-react';
import MarkdownEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { marked } from 'marked';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/';
const MAX_WORDS = 50;


const Navbar = ({ onCreateJob }) => (
  <nav className="bg-white shadow-md">
    <div className="container mx-auto px-4 py-3 flex justify-between items-center">
      <RouterLink to="/jobs" className="flex items-center space-x-2">
        {/* <img src="/path/to/your/logo.png" alt="Blinkhire.ai Logo" className="h-8 w-auto" /> */}
        <span className="text-xl font-bold text-green-600">Blinkhire.ai</span>
      </RouterLink>

      {/* Left-aligned job link with left padding */}
      <div className="flex-grow">
        <RouterLink
          to="/jobdashboard"
          className="border-green-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ml-8"  // Added padding here
        >
          Jobs
        </RouterLink>
      </div>

      {/* Right-aligned create job and user avatar */}
      <div className="flex items-center space-x-4">
        <button
          onClick={onCreateJob}
          className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors duration-200"
        >
          Create Job
        </button>
        <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
          <User className="w-6 h-6 text-gray-600" />
        </div>
      </div>
    </div>
  </nav>
);



const CreateJob = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [jobData, setJobData] = useState({
    title: '',
    description: '',
    salaryMin: '',
    salaryMax: '',
    location: '',
    currency: '',
    isRemote: false,
  });
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [cities, setCities] = useState([]);
  const [locationInput, setLocationInput] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [isEnhancing, setIsEnhancing] = useState(false);


  console.log("Currency Selected: ", currencies)

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all?fields=currencies');
      const uniqueCurrencies = [...new Set(response.data.flatMap(country => 
        Object.entries(country.currencies).map(([code, { name }]) => ({ code, name }))
      ))];
      setCurrencies(uniqueCurrencies);
    } catch (error) {
      console.error('Error fetching currencies:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setJobData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleEditorChange = ({ text }) => {
    const words = text.trim().split(/\s+/).filter(word => word !== '');
    const newWordCount = words.length;
    setWordCount(newWordCount);

    setJobData(prevState => ({
      ...prevState,
      description: text
    }));
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type)) {
      setUploadedFile(file);
      setJobData(prevState => ({ ...prevState, description: '' }));
      setWordCount(0);
    } else {
      setError('Please upload a PDF, DOC, or DOCX file.');
    }
  };

  const handleRemoveFile = () => {
    setUploadedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleLocationInputChange = async (e) => {
    const value = e.target.value;
    setLocationInput(value);

    if (value.length > 2) {
      try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(value)}&limit=5&featuretype=city`);
        setCities(response.data.map(item => ({
          name: item.display_name,
          lat: item.lat,
          lon: item.lon
        })));
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    } else {
      setCities([]);
    }
  };

  const handleCitySelect = (city) => {
    setJobData(prevState => ({
      ...prevState,
      location: city.name
    }));
    setLocationInput(city.name);
    setCities([]);
  };

  const handleEnhanceWithAI = async () => {
    setIsEnhancing(true);
    setError(null);

    if (!jobData.title || !jobData.salaryMin || !jobData.salaryMax || !jobData.currency || (!jobData.location && !jobData.isRemote) || !jobData.description) {
      setError('Please fill in all required fields before enhancing with AI.');
      setIsEnhancing(false);
      return;
    }

    try {
      const response = await axios.post(`https://www.agent.blinkhire.ai/enhance-job`, {
        title: jobData.title,
        salary_min: jobData.salaryMin,
        salary_max: jobData.salaryMax,
        currency: jobData.currency,
        location: jobData.isRemote ? 'Remote' : jobData.location,
        description: jobData.description
      });

      setJobData(prevState => ({
        ...prevState,
        description: response.data.enhanced_description
      }));

      // Update word count for the new description
      const words = response.data.enhanced_description.trim().split(/\s+/).filter(word => word !== '');
      setWordCount(words.length);

    } catch (err) {
      setError('An error occurred while enhancing the job description. Please try again.');
    } finally {
      setIsEnhancing(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    if (jobData.salaryMax && parseFloat(jobData.salaryMax) <= parseFloat(jobData.salaryMin)) {
      setError('Maximum salary must be greater than minimum salary.');
      setIsLoading(false);
      return;
    }

    if (!jobData.location && !jobData.isRemote) {
      setError('Please select a location or mark the position as remote.');
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('title', jobData.title);
    formData.append('salary_min', jobData.salaryMin);
    formData.append('salary_max', jobData.salaryMax);
    formData.append('location', jobData.isRemote ? 'Remote' : jobData.location);
    formData.append('currency', jobData.currency);
    formData.append('is_remote', jobData.isRemote);

    if (uploadedFile) {
      formData.append('file', uploadedFile);
    } else {
      formData.append('description', jobData.description);
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_BASE_URL}api/jobs`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });
      console.log('Job created:', response.data);
      navigate('/jobdashboard');
    } catch (err) {
      setError(err.response?.data?.detail || 'An error occurred while creating the job.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <div className="max-w-3xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <h1 className="text-2xl font-semibold text-gray-900 mb-6">Create New Job</h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4" role="alert">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                  <span className="ml-3 text-sm text-red-700">{error}</span>
                </div>
              </div>
            )}
            
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">Job Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={jobData.title}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                required
              />
            </div>
            
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
              <div>
                <label htmlFor="currency" className="block text-sm font-medium text-gray-700">Currency</label>
                <select
                  id="currency"
                  name="currency"
                  value={jobData.currency}
                  onChange={handleInputChange}
                  className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                  required
                >
                  <option value="">Select currency</option>
                  {currencies.map(currency => (
                    <option key={currency.code} value={currency.code}>{currency.name} ({currency.code})</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="salaryMin" className="block text-sm font-medium text-gray-700">Salary Range (Min)</label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <DollarSign className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="number"
                    id="salaryMin"
                    name="salaryMin"
                    value={jobData.salaryMin}
                    onChange={handleInputChange}
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="salaryMax" className="block text-sm font-medium text-gray-700">Salary Range (Max)</label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <DollarSign className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="number"
                    id="salaryMax"
                    name="salaryMax"
                    value={jobData.salaryMax}
                    onChange={handleInputChange}
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    required
                  />
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="location" className="block text-sm font-medium text-gray-700">Location</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MapPin className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={locationInput}
                  onChange={handleLocationInputChange}
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                  placeholder="Start typing a city name"
                  autoComplete="off"
                  required={!jobData.isRemote}
                />
              </div>
              {cities.length > 0 && (
                <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {cities.map((city, index) => (
                    <li
                      key={index}
                      className="cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-50"
                      onClick={() => handleCitySelect(city)}
                    >
                      {city.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="isRemote"
                  name="isRemote"
                  type="checkbox"
                  checked={jobData.isRemote}
                  onChange={handleInputChange}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="isRemote" className="font-medium text-gray-700">Remote position</label>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Job Description</label>
              {!uploadedFile ? (
                <div className="border border-gray-300 rounded-md overflow-hidden">
                  <MarkdownEditor
                    value={jobData.description}
                    onChange={handleEditorChange}
                    renderHTML={(text) => marked(text)}
                    className="h-64"
                    config={{
                      view: { menu: true, md: true, html: false },
                      canView: { menu: true, md: true, html: false, fullScreen: false, hideMenu: true },
                      shortcuts: true,
                    }}
                  />
                  <div className="bg-gray-50 px-4 py-3">
                    <div className="flex justify-between items-center">
                      <div className="w-full max-w-xs bg-gray-200 rounded-full h-2.5 mr-4">
                        <div 
                          className="bg-green-600 h-2.5 rounded-full transition-all duration-300" 
                          style={{ width: `${Math.min((wordCount / MAX_WORDS) * 100, 100)}%` }}
                        ></div>
                      </div>
                      <span className="text-sm text-gray-500 whitespace-nowrap">{wordCount}/{MAX_WORDS} words</span>
                    </div>
                    <button
                      type="button"
                      onClick={handleEnhanceWithAI}
                      className={`mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${wordCount < MAX_WORDS || isEnhancing ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={wordCount < MAX_WORDS || isEnhancing}
                    >
                      {isEnhancing ? 'Enhancing...' : 'Enhance with AI'}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <Briefcase className="mx-auto h-12 w-12 text-gray-400" />
                    <div className="flex text-sm text-gray-600">
                      <span className="relative cursor-pointer bg-white rounded-md font-medium text-green-600 hover:text-green-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-500">
                        {uploadedFile.name}
                      </span>
                    </div>
                    <p className="text-xs text-gray-500">PDF, DOC up to 10MB</p>
                  </div>
                  <button
                    type="button"
                    onClick={handleRemoveFile}
                    className="ml-2 text-sm font-medium text-red-600 hover:text-red-500"
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            
            <div className="flex justify-between items-center pt-4">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                className="hidden"
              />
              <button
                type="button"
                onClick={() => fileInputRef.current.click()}
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <Upload className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                Upload Job Description
              </button>
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                disabled={isLoading}
              >
                {isLoading ? 'Creating...' : 'Create Job'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateJob;