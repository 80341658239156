import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { Link, Clipboard, DollarSign, MapPin, Calendar, User, Trash2, Wifi, UserPlus, FileText } from 'lucide-react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/';


const SkeletonJobCard = () => (
  <div className="bg-white p-6 rounded-lg shadow-md h-full flex flex-col animate-pulse">
    <div className="flex justify-between items-start mb-4">
      <div className="h-6 bg-gray-200 rounded w-3/4"></div>
      <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
    </div>
    <div className="mb-4 flex items-center">
      <div className="h-4 w-4 bg-gray-200 rounded-full mr-2"></div>
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
    </div>
    <div className="mb-4 flex items-center">
      <div className="h-4 w-4 bg-gray-200 rounded-full mr-2"></div>
      <div className="h-4 bg-gray-200 rounded w-1/3"></div>
    </div>
    <div className="mb-4 flex items-center">
      <div className="h-4 w-4 bg-gray-200 rounded-full mr-2"></div>
      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
    </div>
    <div className="h-20 bg-gray-200 rounded mb-4"></div>
    <div className="mt-auto">
      <div className="h-10 bg-gray-200 rounded w-full"></div>
    </div>
  </div>
);


const Navbar = ({ userProfile, onCreateJob, onLogout }) => (
  <nav className="bg-white shadow-md">
    <div className="container mx-auto px-4 py-3 flex justify-between items-center">
      <RouterLink to="/jobs" className="flex items-center space-x-2">
        <span className="text-xl font-bold text-green-600">Blinkhire.ai</span>
      </RouterLink>

      <div className="flex-grow">
      <RouterLink
          to="/jobdashboard"
          className="border-green-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ml-8"
        >
          Jobs
        </RouterLink>
      </div>

      <div className="flex items-center space-x-4">
        <button
          onClick={onCreateJob}
          className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors duration-200"
        >
          Create Job
        </button>
        <RouterLink
          to={`/profile/${userProfile._id}`}
          className="w-10 h-10 rounded-full overflow-hidden flex items-center justify-center bg-gray-200"
        >
          {userProfile.profile_picture ? (
            <img src={userProfile.profile_picture} alt="Profile" className="w-full h-full object-cover" />
          ) : (
            <User className="w-6 h-6 text-gray-600" />
          )}
        </RouterLink>
        <button
          onClick={onLogout}
          className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
        >
          Logout
        </button>
      </div>
    </div>
  </nav>
);


const WelcomeMessage = ({ userProfile }) => (
  <div className="bg-white shadow-md rounded-lg p-6 mb-8">
    <h2 className="text-2xl font-bold text-gray-800 mb-2">Welcome back, {userProfile.full_name}!</h2>
    <p className="text-gray-600">
      Ready to find your next star employee? Create a new job posting or manage your existing ones below.
    </p>
  </div>
);


const JobCard = ({ job, onViewDetails, onDelete }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const formatSalary = (min, max, currency) => {
    const currencyCode = currency && isValidCurrencyCode(currency) ? currency : 'USD';
    
    try {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return `${formatter.format(min)} - ${formatter.format(max)}`;
    } catch (error) {
      console.error('Error formatting salary:', error);
      return `${currencyCode} ${min.toLocaleString()} - ${max.toLocaleString()}`;
    }
  };

  const isValidCurrencyCode = (code) => {
    try {
      new Intl.NumberFormat('en-US', { style: 'currency', currency: code });
      return true;
    } catch (error) {
      return false;
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Date not specified';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const copyLinkToClipboard = () => {
    const frontendUrl = process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000';
    const jobLink = `${frontendUrl}${job.link}`;
    navigator.clipboard.writeText(jobLink).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 h-full flex flex-col relative">
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-xl font-semibold text-gray-800">{job.title}</h3>
        <div className="flex space-x-2">
          <button
            onClick={copyLinkToClipboard}
            className="text-green-600 hover:text-green-700 transition-colors duration-200"
            title="Copy job link"
          >
            {copySuccess ? <Clipboard className="w-5 h-5" /> : <Link className="w-5 h-5" />}
          </button>
          <button
            onClick={() => onDelete(job.id)}
            className="text-red-500 hover:text-red-600 transition-colors duration-200"
            title="Delete job"
          >
            <Trash2 className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="mb-4 flex items-center text-gray-600">
        {job.location === "Remote" ? (
          <Wifi className="w-4 h-4 mr-2" />
        ) : (
          <MapPin className="w-4 h-4 mr-2" />
        )}
        <span>{job.location}</span>
      </div>

      <div className="mb-4 flex items-center text-gray-600">
        <DollarSign className="w-4 h-4 mr-2" />
        <span>{formatSalary(job.salary_min, job.salary_max, job.currency)}</span>
      </div>

      {job.expiration && (
        <div className="mb-4 flex items-center text-gray-600">
          <Calendar className="w-4 h-4 mr-2" />
          <span>Expires: {formatDate(job.expiration)}</span>
        </div>
      )}

      <p className="text-gray-600 mb-4 flex-grow">
        {job.description && job.description.length > 150
          ? `${job.description.substring(0, 150)}...`
          : job.description}
      </p>

      <div className="mt-auto">
        <button 
          onClick={() => onViewDetails(job.id, job.title)}
          className="w-full py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors duration-200 font-medium"
        >
          View Details
        </button>
      </div>

      <div className="absolute bottom-2 right-2 bg-green-100 text-green-800 px-2 py-1 rounded text-sm flex items-center">
        <UserPlus className="w-4 h-4 mr-1" />
        {job.resume_count} applicant{job.resume_count !== 1 ? 's' : ''}
      </div>

      {copySuccess && (
        <div className="absolute top-2 right-2 bg-green-100 text-green-800 px-2 py-1 rounded text-sm">
          Link copied!
        </div>
      )}
    </div>
  );
};


const NoJobsMessage = () => (
  <div className="text-center py-10">
    <FileText className="mx-auto h-12 w-12 text-gray-400" />
    <h3 className="mt-2 text-sm font-medium text-gray-900">No jobs posted</h3>
    <p className="mt-1 text-sm text-gray-500">Get started by creating a new job posting.</p>
    <div className="mt-6">
      <RouterLink
        to="/create-job"
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        <UserPlus className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Create new job
      </RouterLink>
    </div>
  </div>
);


const JobDashboard = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const storedUserProfile = localStorage.getItem('user_profile');
    console.log(storedUserProfile)
    if (storedUserProfile) {
      setUserProfile(JSON.parse(storedUserProfile));
    }
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await axios.get(`${API_BASE_URL}api/jobs`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setJobs(response.data);
      setLoading(false);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        setError('Failed to fetch jobs. Please try again later.');
        setLoading(false);
      }
    }
  };

  const handleViewDetails = (jobId, jobTitle) => {
    navigate(`/job-detail/${jobId}`, { state: { jobTitle } });
  };

  const handleCreateJob = () => {
    navigate('/create-job');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_profile');
    navigate('/login');
  };

  const handleDeleteJob = async (jobId) => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${API_BASE_URL}api/jobs/${jobId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setJobs(jobs.filter(job => job.id !== jobId));
      } catch (error) {
        console.error('Failed to delete job:', error);
        alert('Failed to delete job. Please try again.');
      }
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {[...Array(6)].map((_, index) => (
            <SkeletonJobCard key={index} />
          ))}
        </div>
      );
    }

    if (error) {
      return <div className="text-center py-4 text-red-500">{error}</div>;
    }

    if (jobs.length === 0) {
      return <NoJobsMessage />;
    }

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {jobs.map((job) => (
          <JobCard
            key={job.id}
            job={job}
            onViewDetails={handleViewDetails}
            onDelete={handleDeleteJob}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {userProfile && (
        <Navbar userProfile={userProfile} onCreateJob={handleCreateJob} onLogout={handleLogout} />
      )}
      <div className="container mx-auto px-4 py-8">
        {userProfile && <WelcomeMessage userProfile={userProfile} />}
        {renderContent()}
      </div>
    </div>
  );
};

export default JobDashboard;