import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicyPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <button onClick={() => navigate(-1)} className="mr-4 text-gray-600 hover:text-gray-900">
              <ArrowLeft className="w-6 h-6" />
            </button>
            <h1 className="text-2xl font-bold text-green-500">blinkhire.ai Privacy Policy</h1>
          </div>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white shadow-md rounded-lg p-6 max-w-4xl mx-auto">
          <h2 className="text-2xl font-semibold mb-6">Privacy Policy</h2>
          
          <section className="mb-8">
            <h3 className="text-xl font-semibold mb-3">1. Information We Collect</h3>
            <p className="mb-2">We collect information you provide directly to us, such as:</p>
            <ul className="list-disc pl-6 mb-2">
              <li>Account information (e.g., name, email address, password)</li>
              <li>Profile information (e.g., resume, work history, education)</li>
              <li>Job posting information (for employers)</li>
              <li>Communication data (e.g., messages sent through our platform)</li>
            </ul>
            <p>We also collect certain information automatically when you use our services, including:</p>
            <ul className="list-disc pl-6">
              <li>Log data (e.g., IP address, browser type, pages visited)</li>
              <li>Device information</li>
              <li>Cookie and similar technology data</li>
            </ul>
          </section>

          <section className="mb-8">
            <h3 className="text-xl font-semibold mb-3">2. How We Use Your Information</h3>
            <p>We use the information we collect to:</p>
            <ul className="list-disc pl-6">
              <li>Provide, maintain, and improve our services</li>
              <li>Match job seekers with relevant job opportunities</li>
              <li>Communicate with you about our services</li>
              <li>Analyze usage patterns and improve user experience</li>
              <li>Protect against fraud and unauthorized access</li>
            </ul>
          </section>

          <section className="mb-8">
            <h3 className="text-xl font-semibold mb-3">3. Information Sharing and Disclosure</h3>
            <p>We may share your information in the following circumstances:</p>
            <ul className="list-disc pl-6">
              <li>With employers when you apply for a job</li>
              <li>With service providers who perform services on our behalf</li>
              <li>In response to legal requests or to protect our rights</li>
              <li>In connection with a merger, sale, or asset transfer</li>
            </ul>
          </section>

          <section className="mb-8">
            <h3 className="text-xl font-semibold mb-3">4. Data Security</h3>
            <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage.</p>
          </section>

          <section className="mb-8">
            <h3 className="text-xl font-semibold mb-3">5. Your Rights and Choices</h3>
            <p>You have the right to:</p>
            <ul className="list-disc pl-6">
              <li>Access, correct, or delete your personal information</li>
              <li>Object to or restrict certain processing of your data</li>
              <li>Data portability</li>
              <li>Withdraw consent at any time, where processing is based on consent</li>
            </ul>
          </section>

          <section className="mb-8">
            <h3 className="text-xl font-semibold mb-3">6. Changes to This Privacy Policy</h3>
            <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Last Updated" date.</p>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-3">7. Contact Us</h3>
            <p>If you have any questions about this privacy policy, please contact us at:</p>
            <p className="font-semibold">Email: privacy@blinkhire.ai</p>
            <p className="font-semibold">Address: Askari 4, Karachi, Pakistan</p>
          </section>

          <p className="mt-8 text-sm text-gray-600">Last Updated: [Current Date]</p>
        </div>
      </main>

      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 blinkhire.ai. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicyPage;