import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { Linkedin } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/';

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setIsLogin(params.get('mode') !== 'signup');
    
    // Check for error from LinkedIn callback
    const { state } = location;
    if (state && state.error) {
      setError(state.error);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      let response;
      if (isLogin) {
        response = await axios.post(`${API_BASE_URL}api/login`, {
          username: email,
          password: password,
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
  
        const { access_token, user_profile } = response.data;
        
        localStorage.setItem('token', access_token);
        localStorage.setItem('user_profile', JSON.stringify(user_profile));
        
        navigate('/jobdashboard');
      } else {
        response = await axios.post(`${API_BASE_URL}api/signup`, {
          email: email,
          password: password,
          full_name: fullName,
        });
  
        // For signup, redirect to login without storing anything
        navigate('/login');
      }
    } catch (error) {
      handleError(error);
    }
  };

  
  const handleGoogleAuth = async (credentialResponse) => {
    try {
      const endpoint = isLogin ? 'api/google-login' : 'api/google-signup';
      const response = await axios.post(`${API_BASE_URL}${endpoint}`, 
        { token: credentialResponse.credential },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (!isLogin) {
        // For signup, redirect to login without storing anything
        navigate('/login');
      } else {
        // For login, store token and user profile
        const { access_token, user_profile } = response.data;
        
        localStorage.setItem('token', access_token);
        localStorage.setItem('user_profile', JSON.stringify(user_profile));
        
        navigate('/jobdashboard');
      }
    } catch (error) {
      handleError(error);
    }
  };

  console.log("process.env.REACT_APP_LINKEDIN_CLIENT_ID: ",process.env.REACT_APP_LINKEDIN_CLIENT_ID)

  const handleLinkedInLogin = () => {
    const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
    const redirectUri = encodeURIComponent(`${window.location.origin}/linkedin-callback`);
    console.log("redirectUri: ", redirectUri)
    const scope = encodeURIComponent('openid profile email');
    // const state = encodeURIComponent(JSON.stringify({
    //   timestamp: Date.now().toString(),
    //   mode: isLogin ? 'login' : 'signup'
    // }));
    const state = btoa(JSON.stringify({
      csrf: uuidv4(),
      timestamp: Date.now(),
      mode: isLogin ? 'login' : 'signup'
    }));
    
    // Store the state in localStorage
    localStorage.setItem('linkedInAuthState', state);
    
    const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
    
    window.location.href = linkedInAuthUrl;

  }
  
    

  const handleLinkedInAuth = async (code) => {
    try {
      const endpoint = isLogin ? 'api/linkedin-login' : 'api/linkedin-signup';
      const response = await axios.post(`${API_BASE_URL}${endpoint}`,
        { code: code },  // Ensure the code is sent as an object
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      const { access_token } = response.data;
      localStorage.setItem('token', access_token);
      navigate('/jobdashboard');
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response) {
      setError(error.response.data.detail || 'An error occurred. Please try again.');
    } else if (error.request) {
      setError('No response from server. Please try again later.');
    } else {
      setError('An unexpected error occurred. Please try again.');
    }
    console.error('Error:', error);
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setError('');
    navigate(isLogin ? '/login?mode=signup' : '/login');
  }

  return (
    <div className="min-h-screen flex bg-white">
      <div className="flex-1 flex flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              {isLogin ? 'Log In' : 'Sign Up'}
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              {isLogin 
                ? 'Welcome back! Please enter your details.' 
                : 'Create an account to get started'}
            </p>
          </div>

          <div className="mt-8">
            <form onSubmit={handleSubmit} className="space-y-6">
              {!isLogin && (
                <div>
                  <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                    Full Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="fullName"
                      name="fullName"
                      type="text"
                      autoComplete="name"
                      required={!isLogin}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      placeholder="John Doe"
                    />
                  </div>
                </div>
              )}

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email Address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    placeholder="john@example.com"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete={isLogin ? "current-password" : "new-password"}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    placeholder="••••••••"
                  />
                </div>
              </div>

              {isLogin && (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <a href="#" className="font-medium text-green-600 hover:text-green-500">
                      Forgot Password?
                    </a>
                  </div>
                </div>
              )}

              {error && (
                <div className="text-red-500 text-sm">{error}</div>
              )}

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  {isLogin ? 'Log In' : 'Sign Up'}
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or continue with</span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-2 gap-3">
                <GoogleLogin
                  onSuccess={handleGoogleAuth}
                  onError={() => setError('Google authentication failed. Please try again.')}
                  useOneTap
                />
                <button
                  onClick={handleLinkedInLogin}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <Linkedin className="h-5 w-5 mr-2" />
                  LinkedIn
                </button>
              </div>
            </div>

            <p className="mt-6 text-center text-sm text-gray-600">
              {isLogin ? "No account yet?" : "Already have an account?"}{' '}
              <button onClick={toggleMode} className="font-medium text-green-600 hover:text-green-500">
                {isLogin ? 'Sign Up' : 'Log In'}
              </button>
            </p>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1 bg-green-200">
        <div className="absolute inset-0 flex items-center justify-center">
          <img
            className="h-full w-full object-cover"
            src="/login.jpg"
            alt="Login illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;