import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link as RouterLink, useParams } from 'react-router-dom';
import { User, Mail, Edit2, Camera, Loader } from 'lucide-react';
import axios from 'axios';
import { gapi } from 'gapi-script';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/';
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const SCOPES = "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/calendar.events";

const Navbar = ({ userProfile, onLogout }) => (
  <nav className="bg-white shadow-md">
    <div className="container mx-auto px-4 py-3 flex justify-between items-center">
      <RouterLink to="/dashboard" className="flex items-center space-x-2">
        <span className="text-xl font-bold text-green-600">Blinkhire.ai</span>
      </RouterLink>
      <div className="flex-grow">
        <RouterLink to="/jobdashboard" className="border-green-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ml-8">
          Jobs
        </RouterLink>
      </div>
      <div className="flex items-center space-x-4">
        {userProfile && userProfile._id && (
          <RouterLink to={`/profile/${userProfile._id}`} className="w-10 h-10 rounded-full overflow-hidden flex items-center justify-center bg-gray-200">
            {userProfile.profile_picture_url ? (
              <img src={userProfile.profile_picture_url} alt="Profile" className="w-full h-full object-cover" />
            ) : (
              <User className="w-6 h-6 text-gray-600" />
            )}
          </RouterLink>
        )}
        <button onClick={onLogout} className="text-gray-600 hover:text-gray-800 transition-colors duration-200">
          Logout
        </button>
      </div>
    </div>
  </nav>
);

const ProfilePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);

  const fetchProfile = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await axios.get(`${API_BASE_URL}api/profile/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setProfile(response.data.user_profile);
      setNewName(response.data.user_profile.full_name);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching profile:', err);
      if (err.response && err.response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        setError('Failed to fetch profile. Please try again later.');
        setLoading(false);
      }
    }
  }, [id, navigate]);

  useEffect(() => {
    const userProfileString = localStorage.getItem('user_profile');
    if (userProfileString) {
      const userProfile = JSON.parse(userProfileString);
      setProfile(userProfile);
      setNewName(userProfile.full_name);
      setLoading(false);
    } else {
      fetchProfile();
    }
    initializeGoogleApi();
  }, []);

  const initializeGoogleApi = () => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: SCOPES,
      }).then(() => {
        console.log('Google API client initialized');
      }).catch((error) => {
        console.error('Error initializing Google API client:', error);
        setError('Failed to initialize Google API. Please try again later.');
      });
    });
  };

  const handleConnectGoogle = () => {
    if (!profile.is_google_connected) {
        const clientId = '377342915801-rlk1456ksp4t5hmgbs14amrv2bdfb8ks.apps.googleusercontent.com';
        const redirectUri = 'http://localhost:3000/google-callback';
        const scope = 'email profile https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/calendar';
        
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}&access_type=offline&prompt=consent`;
      
        window.location.href = authUrl;

      
          updateProfileState({ is_google_connected: true});
        
    }
    else {
      const response = axios.post(`${API_BASE_URL}api/disconnect-google`, {user_id: id}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      console.log("here")
      if (response) {
        console.log('here')
        updateProfileState({ is_google_connected: false, connected_gmail: null });
      }
    }
  };

  const handleNameChange = async () => {
    try {
      setIsUpdating(true);
      const token = localStorage.getItem('token');
      const response = await axios.put(`${API_BASE_URL}api/profile`, 
        { full_name: newName, user_id: id },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      console.log("here ", response.data)
      if (response.data) {
        console.log("Updated user profile: ", response.data.user_profile)
        const updatedProfile = response.data.user_profile;
        setProfile(updatedProfile);
        localStorage.setItem('user_profile', JSON.stringify(updatedProfile));
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error updating name:', error);
      setError('Failed to update name. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleProfilePictureUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append("user_id", id);
      try {
        setIsUpdating(true);
        const token = localStorage.getItem('token');
        const response = await axios.post(`${API_BASE_URL}api/profile/upload-picture`, 
          formData,
          { 
            headers: { 
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            } 
          }
        );
        if (response.data) {
          const updatedProfile = response.data.user_profile;
          setProfile(updatedProfile);
          localStorage.setItem('user_profile', JSON.stringify(updatedProfile));
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
        setError('Failed to upload profile picture. Please try again.');
      } finally {
        setIsUpdating(false);
      }
    }
  };

  const updateProfileState = (newData) => {
    const updatedProfile = { ...profile, ...newData };
    setProfile(updatedProfile);
    localStorage.setItem('user_profile', JSON.stringify(updatedProfile));
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_profile');
    navigate('/login');
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  return (
    <>
      <Navbar userProfile={profile} onLogout={handleLogout} />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">{profile.full_name}'s Profile</h1>
        <div className="bg-white shadow-md rounded-lg p-6">
          <div className="flex items-center mb-4">
            <div className="relative">
              <div className="w-20 h-20 rounded-full overflow-hidden mr-4">
                {profile.profile_picture_url ? (
                  <img src={profile.profile_picture_url} alt="Profile" className="w-full h-full object-cover" />
                ) : (
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                    <User className="w-10 h-10 text-gray-400" />
                  </div>
                )}
              </div>
              <label htmlFor="profile-picture-upload" className="absolute bottom-0 right-0 bg-green-500 rounded-full p-1 cursor-pointer">
                <Camera className="w-4 h-4 text-white" />
                <input 
                  id="profile-picture-upload" 
                  type="file" 
                  className="hidden" 
                  accept="image/*"
                  onChange={handleProfilePictureUpload}
                  disabled={isUpdating}
                />
              </label>
            </div>
            <div>
              {isEditing ? (
                <div className="flex items-center">
                  <input 
                    type="text" 
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    className="text-2xl font-semibold border-b border-gray-300 focus:outline-none focus:border-green-500"
                    disabled={isUpdating}
                  />
                  <button 
                    onClick={handleNameChange} 
                    className="ml-2 text-green-500 hover:text-green-600"
                    disabled={isUpdating}
                  >
                    {isUpdating ? <Loader className="w-4 h-4 animate-spin" /> : 'Save'}
                  </button>
                </div>
              ) : (
                <h2 className="text-2xl font-semibold flex items-center">
                  {profile.full_name}
                  <button 
                    onClick={() => setIsEditing(true)} 
                    className="ml-2 text-gray-400 hover:text-gray-600"
                    disabled={isUpdating}
                  >
                    <Edit2 className="w-4 h-4" />
                  </button>
                </h2>
              )}
              <p className="text-gray-600">{profile.email}</p>
            </div>
          </div>
          
          {profile.is_google_connected && profile.connected_gmail && (
            <div className="mt-4 p-4 bg-green-50 rounded-md">
              <div className="flex items-center mb-2">
                <Mail className="w-5 h-5 text-green-600 mr-2" />
                <span className="font-medium text-green-700">Connected Google Account</span>
              </div>
              <p className="text-green-600">{profile.connected_gmail}</p>
            </div>
          )}

          <div className="mt-6 space-y-4">
            <button
              onClick={handleConnectGoogle}
              disabled={isUpdating}
              className={`flex items-center justify-center w-full px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                profile.is_google_connected ? 'bg-red-600 hover:bg-red-700' : 'bg-blue-600 hover:bg-blue-700'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                isUpdating ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isUpdating ? (
                <Loader className="w-5 h-5 mr-2 animate-spin" />
              ) : null}
              {profile.is_google_connected ? 'Disconnect Google Account' : 'Connect Google Account'}
            </button>
            {profile.is_google_connected && (
              <p className="text-green-600 text-center">
                Your Google account is connected. You can now use Gmail and Google Calendar features from other parts of the application.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;