import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { User, MessageSquare, MapPin, Briefcase, GraduationCap, Code, X, Send } from 'lucide-react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/';

const Navbar = ({ onChatWithAI }) => (
  <nav className="bg-white shadow-md">
    <div className="container mx-auto px-4 py-3 flex justify-between items-center">
      <Link to="/jobdashboard" className="flex items-center space-x-2">
        <span className="text-xl font-bold text-green-600">Blinkhire.ai</span>
      </Link>
      <div className="flex-grow">
        <Link
          to="/candidates"
          className="border-green-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ml-8"
        >
          Candidates
        </Link>
      </div>
      <div className="flex items-center space-x-4">
        <button
          onClick={onChatWithAI}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center"
        >
          <MessageSquare className="w-4 h-4 mr-2" />
          Chat with AI
        </button>
        <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
          <User className="w-6 h-6 text-gray-600" />
        </div>
      </div>
    </div>
  </nav>
);

const ChatSlider = ({ isOpen, onClose, candidateName, messages, inputMessage, setInputMessage, handleSendMessage }) => {
  return (
    <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
      <div className="flex flex-col h-full">
        <div className="bg-green-600 text-white p-4 flex justify-between items-center">
          <h2 className="text-xl font-semibold">Chat with {candidateName}</h2>
          <button onClick={onClose} className="text-white hover:text-gray-200">
            <X size={24} />
          </button>
        </div>
        <div className="flex-grow overflow-y-auto p-4 space-y-4">
          {messages.map((message, index) => (
            <div 
              key={index} 
              className={`max-w-[80%] p-3 rounded-lg ${
                message.type === 'user' 
                  ? 'bg-blue-500 text-white ml-auto rounded-br-none' 
                  : 'bg-gray-200 text-gray-800 rounded-bl-none'
              }`}
            >
              {message.content}
            </div>
          ))}
        </div>
        <div className="p-4 border-t">
          <div className="flex items-center bg-gray-100 rounded-full overflow-hidden">
            <input 
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Type your message..."
              className="flex-grow px-4 py-2 bg-transparent focus:outline-none"
            />
            <button 
              onClick={handleSendMessage} 
              className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors duration-200"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CandidatePage = () => {
  const { resumeId } = useParams();
  const navigate = useNavigate();
  const [resumeData, setResumeData] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  console.log("Resume Data: ",resumeData)

  useEffect(() => {
    const fetchResumeData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}get_resume_by_id/${resumeId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch resume data');
        }
        const data = await response.json();
        setResumeData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchResumeData();
  }, [resumeId]);

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      setMessages([...messages, { type: 'user', content: inputMessage }]);
      setInputMessage('');
      // Here you would typically send the message to your backend
      // and then add the response to the messages array
    }
  };

  const handleChatWithAI = () => {
    navigate(`/chat/${resumeData.job_id}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!resumeData) return <div>No data available</div>;

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Navbar onChatWithAI={handleChatWithAI} />
      <div className="container mx-auto p-4 flex-grow">
        <div className="bg-white p-6 rounded-lg shadow mb-6">
          <h1 className="text-3xl font-bold mb-4">{resumeData.name}'s Resume</h1>
          <div className="flex items-center text-gray-600 mb-2">
            <MapPin className="w-5 h-5 mr-2" />
            <span>{resumeData.contact.address || 'Location not specified'}</span>
          </div>
          <p className="text-gray-600 mb-4">{resumeData.objective}</p>
          <button 
            onClick={() => setIsChatOpen(true)} 
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors duration-200"
          >
            Chat with Candidate
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-4 flex items-center">
              <Briefcase className="w-5 h-5 mr-2" />
              Experience
            </h2>
            {resumeData.experience.map((exp, index) => (
              <div key={index} className="mb-4">
                <h3 className="font-semibold">{exp.title} at {exp.company}</h3>
                <p className="text-sm text-gray-600">
                  {exp.start_date} - {exp.current ? 'Present' : exp.end_date}
                </p>
                <p className="mt-1">{exp.description}</p>
              </div>
            ))}
          </div>

          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-4 flex items-center">
              <GraduationCap className="w-5 h-5 mr-2" />
              Education
            </h2>
            {resumeData.education.map((edu, index) => (
              <div key={index} className="mb-4">
                <h3 className="font-semibold">{edu.school}</h3>
                <p className="text-sm text-gray-600">
                  {edu.degree} in {edu.field_of_study}
                </p>
                <p className="text-sm text-gray-600">
                  {edu.start_date} - {edu.current ? 'Present' : edu.end_date}
                </p>
              </div>
            ))}
          </div>

          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-4 flex items-center">
              <Code className="w-5 h-5 mr-2" />
              Skills
            </h2>
            <div className="flex flex-wrap gap-2">
              {resumeData.skills.map((skill, index) => (
                <span key={index} className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-sm">
                  {skill}
                </span>
              ))}
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-4">Contact Information</h2>
            <p>Email: {resumeData.contact.email}</p>
            <p>Phone: {resumeData.contact.phone}</p>
          </div>
        </div>
      </div>

      <ChatSlider 
        isOpen={isChatOpen}
        onClose={() => setIsChatOpen(false)}
        candidateName={resumeData.name}
        messages={messages}
        inputMessage={inputMessage}
        setInputMessage={setInputMessage}
        handleSendMessage={handleSendMessage}
      />
    </div>
  );
};

export default CandidatePage;