// src/components/ChatHeader.js

import React from 'react';
import { DocumentTextIcon, ChartBarIcon } from '@heroicons/react/24/outline';

const ChatHeader = ({ job, isAllJobs }) => {
    if (isAllJobs) {
        return (
            <div className="bg-gray-800 p-4 border-b border-gray-700">
                <h2 className="text-xl font-semibold text-white">All Jobs</h2>
            </div>
        );
    }

    if (!job) return null;

    return (
        <div className="bg-gray-800 p-4">
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-white">{job.title}</h2>
                <div className="flex space-x-2">
                    <button className="bg-gray-700 bg-opacity-80 hover:bg-opacity-100 text-white px-3 py-1.5 rounded-md text-sm transition duration-300 flex items-center justify-center">
                        <DocumentTextIcon className="h-4 w-4 mr-1.5" />
                        View Job Description
                    </button>
                    <button className="bg-gray-700 bg-opacity-80 hover:bg-opacity-100 text-white px-3 py-1.5 rounded-md text-sm transition duration-300 flex items-center justify-center">
                        <ChartBarIcon className="h-4 w-4 mr-1.5" />
                        View Job Statistics
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatHeader;