import React from 'react';
import { Users, FileText, Search, BarChart2, MessageSquare, Workflow, Facebook, Linkedin, Twitter, Instagram } from 'lucide-react';
import { useNavigate } from 'react-router-dom';


const BlinkhireLandingPage = () => {
    const navigate = useNavigate();

    const features = [
        { name: 'AI Talent Matching', icon: Users },
        { name: 'Easy Job Posting', icon: FileText },
        { name: 'CV Search & Filter', icon: Search },
        { name: 'Real-Time Analytics', icon: BarChart2 },
        { name: 'Candidate Communication', icon: MessageSquare },
        { name: 'Custom Workflows', icon: Workflow }
      ];
    
      const plans = [
        { name: 'Free Plan', price: '$0', features: ['Feature One', 'Feature Two', 'Feature Three'] },
        { name: 'Professional Plan', price: '$55', features: ['Pro Feature One', 'Pro Feature Two', 'Pro Feature Three'] },
        { name: 'Enterprise Plan', price: '$95', features: ['Enterprise Feature One', 'Enterprise Feature Two', 'Enterprise Feature Three'] },
      ];
    
      const faqs = [
        'What is Blinkhire?',
        'How do I get started with Blinkhire?',
        'What are the key differences between the pricing plans?',
        'Can I cancel or pause my subscription?',
        'How does Blinkhire ensure the security of my data?'
      ];



  return (
    <div className="flex flex-col min-h-screen">
      <header className="flex justify-between items-center p-4">
        <div className="text-2xl font-bold text-green-500">blinkhire.ai</div>
        <nav className="space-x-4">
          <a href="#" className="text-gray-600">Home</a>
          <a href="#" className="text-gray-600">Features</a>
          <a href="#" className="text-gray-600">Pricing</a>
          <a href="#" className="text-gray-600">About Us</a>
          <a href="#" className="text-gray-600">Contact Us</a>
        </nav>
        <div className="space-x-2">
                    <button 
                        onClick={() => navigate('/login')}
                        className="px-4 py-2 border border-green-500 text-green-500 rounded">
                        Login
                    </button>
                    <button 
                        onClick={() => navigate('/login?mode=signup')}
                        className="px-4 py-2 bg-green-500 text-white rounded">
                        Sign Up
                    </button>
        </div>
      </header>

      <main className="flex-grow">
        <section className="relative h-[500px]">
          <img src="/header.jpg" alt="Hero" className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="text-white max-w-3xl text-center px-4">
              <h1 className="text-4xl font-bold mb-4">Empower Your Hiring with Blinkhire</h1>
              <p className="mb-6">
                Blinkhire streamlines your hiring process by leveraging advanced AI technology to match you 
                with the perfect candidates quickly and efficiently. Whether you're posting a new job or 
                sifting through applications, our platform offers a seamless and intuitive experience 
                designed to meet your recruitment needs.
              </p>
              <button className="px-6 py-3 bg-green-500 text-white rounded-full text-lg font-semibold">Create a Job</button>
            </div>
          </div>
        </section>

        <section className="py-16 px-8">
          <div className="max-w-6xl mx-auto flex items-center space-x-12">
            <div className="flex-1">
              <img src="/dashboard.png" alt="Dashboard" className="rounded-lg shadow-lg" />
            </div>
            <div className="flex-1">
              <h2 className="text-3xl font-bold mb-4">
                <span className="text-gray-800">Revolutionizing </span>
                <span className="text-green-500">Talent</span>
                <span className="text-gray-800"> Acquisition</span>
              </h2>
              <p className="text-gray-600">
                Blinkhire is a cutting-edge platform designed to streamline the hiring process for modern businesses. Powered by the advanced technology of Modularity AI, Blinkhire simplifies recruitment with an intuitive interface that connects you with top talent effortlessly.
              </p>
            </div>
          </div>
        </section>
      </main>

      <section className="py-16 px-8 bg-gray-100">
          <h2 className="text-3xl font-bold text-center mb-12">
            Key Features That Set <span className="text-green-500">Blinkhire</span> Apart
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {features.map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center">
                <feature.icon className="w-16 h-16 mx-auto mb-4 text-green-500" />
                <h3 className="font-bold mb-2">{feature.name}</h3>
                <p className="text-gray-600">Brief description of the feature and its benefits.</p>
              </div>
            ))}
          </div>
        </section>

      <section className="py-16 px-8">
        <h2 className="text-3xl font-bold text-center mb-12">
          Choose the <span className="text-green-500">Plan</span> That's Right for You
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            { name: 'Free Plan', price: '$0', features: ['Feature One', 'Feature Two', 'Feature Three'] },
            { name: 'Professional Plan', price: '$55', features: ['Pro Feature One', 'Pro Feature Two', 'Pro Feature Three'] },
            { name: 'Enterprise Plan', price: '$95', features: ['Enterprise Feature One', 'Enterprise Feature Two', 'Enterprise Feature Three'] },
          ].map((plan, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center">
              <h3 className="font-bold text-xl mb-4">{plan.name}</h3>
              <p className="text-3xl font-bold text-green-500 mb-4">{plan.price}</p>
              <ul className="mb-6">
                {plan.features.map((feature, fIndex) => (
                  <li key={fIndex} className="mb-2">✓ {feature}</li>
                ))}
              </ul>
              <button className="bg-green-500 text-white px-6 py-2 rounded">Get Started</button>
            </div>
          ))}
        </div>
      </section>

      <section className="py-16 px-8 bg-gray-100">
        <div className="max-w-6xl mx-auto flex items-center space-x-12">
          <div className="flex-1">
            <h2 className="text-3xl font-bold mb-4">
              Innovating Recruitment for the <span className="text-green-500">Modern Era</span>
            </h2>
            <p className="text-gray-600 mb-6">
              Brief description of how Blinkhire is revolutionizing the recruitment process for modern businesses.
            </p>
          </div>
          <div className="flex-1">
            <img src="/header_2.jpg" alt="Modern Recruitment" className="rounded-lg shadow-lg" />
          </div>
        </div>
      </section>

      <section className="py-16 px-8">
        <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions (FAQs)</h2>
        <div className="max-w-3xl mx-auto">
          {['What is Blinkhire?', 'How do I get started with Blinkhire?', 'What are the key differences between the pricing plans?', 'Can I cancel or pause my subscription?', 'How does Blinkhire ensure the security of my data?'].map((question, index) => (
            <div key={index} className="mb-6">
              <h3 className="font-bold mb-2">{question}</h3>
              <p className="text-gray-600">Brief answer to the frequently asked question.</p>
            </div>
          ))}
        </div>
      </section>

      <footer className="bg-gray-800 text-white py-12">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="font-bold text-lg mb-4">Blinkhire</h3>
            <p>Smart, intuitive AI-powered hiring solutions for modern businesses.</p>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">Quick Links</h3>
            <ul>
              {['Home', 'Features', 'Pricing', 'About Us', 'Contact Us'].map((link, index) => (
                <li key={index} className="mb-2"><a href="#" className="hover:text-green-500">{link}</a></li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">Contact Us</h3>
            <p>support@blinkhire.com</p>
            <p>+1 234 567 8900</p>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">Social Media</h3>
            <div className="flex space-x-4">
              {['Facebook', 'LinkedIn', 'Twitter', 'Instagram'].map((social, index) => (
                <a key={index} href="#" className="hover:text-green-500">{social}</a>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-400">
          © 2024 Blinkhire. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default BlinkhireLandingPage;