import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { MapPin, User, MessageSquare, ExternalLink } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { MapContainer, TileLayer, CircleMarker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/';


const Navbar = ({ onCreateJob, onChatWithAI }) => (
  <nav className="bg-white shadow-md">
    <div className="container mx-auto px-4 py-3 flex justify-between items-center">
      <RouterLink to="/jobdashboard" className="flex items-center space-x-2">
        <span className="text-xl font-bold text-green-600">Blinkhire.ai</span>
      </RouterLink>
      <div className="flex-grow">
        <RouterLink
          to="/jobdashboard"
          className="border-green-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ml-8"
        >
          Jobs
        </RouterLink>
      </div>
      <div className="flex items-center space-x-4">
        <button
          onClick={onCreateJob}
          className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors duration-200"
        >
          Create Job
        </button>
        <button
          onClick={onChatWithAI}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center"
        >
          <MessageSquare className="w-4 h-4 mr-2" />
          Chat with AI
        </button>
        <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
          <User className="w-6 h-6 text-gray-600" />
        </div>
      </div>
    </div>
  </nav>
);

const JobDetail = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('analytics');
  const [jobData, setJobData] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log("Job Detail: ", jobData)

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/jobs_detail_and_analytics/${jobId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch job details');
        }
        const data = await response.json();
        setJobData(data.job_details);
        setAnalytics(data.analytics);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const cityData = useMemo(() => {
    if (!analytics || !analytics.unique_cities) return [];
    return analytics.unique_cities.map(city => ({
      ...city,
      count: analytics.top_candidates.filter(candidate => candidate.city === city.city).length
    }));
  }, [analytics]);

  const getColor = (count) => {
    const maxCount = Math.max(...cityData.map(city => city.count));
    const minCount = Math.min(...cityData.map(city => city.count));
    const fraction = (count - minCount) / (maxCount - minCount || 1);
    const green = Math.floor(100 + fraction * 155);
    return `rgb(0, ${green}, 0)`;
  };

  const handleChatWithAI = () => {
    navigate(`/chat/${jobId}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!jobData || !analytics) return <div>No data available</div>;

  const CandidateMap = () => {
    const bounds = cityData.map(city => [city.lat, city.lng]);
    const center = bounds.length > 0 
      ? bounds.reduce((acc, val) => [acc[0] + val[0], acc[1] + val[1]]).map(v => v / bounds.length)
      : [39.8283, -98.5795]; // Default to center of USA if no data

    return (
      <MapContainer center={center} zoom={4} style={{ height: '400px', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {cityData.map((city, index) => (
          <CircleMarker
            key={index}
            center={[city.lat, city.lng]}
            radius={Math.sqrt(city.count) * 5}
            fillColor={getColor(city.count)}
            color="#000"
            weight={1}
            opacity={1}
            fillOpacity={0.8}
          >
            <Popup>
              <div>
                <h3 className="font-semibold">{city.city}</h3>
                <p>Applicants: {city.count}</p>
                <ul>
                  {analytics.top_candidates
                    .filter(candidate => candidate.city === city.city)
                    .map((candidate, idx) => (
                      <li key={idx}>{candidate.name} (Score: {candidate.score}%)</li>
                    ))}
                </ul>
              </div>
            </Popup>
          </CircleMarker>
        ))}
      </MapContainer>
    );
  };

  const AnalyticsTab = () => (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-semibold mb-4">{jobData.title}</h2>
        <p className="text-gray-600 mb-4">{jobData.description}</p>
        <div className="flex items-center text-gray-600 mb-2">
          <MapPin className="w-5 h-5 mr-2" />
          <span>{jobData.location}</span>
        </div>
        <div className="text-gray-600">
          Salary Range: ${jobData.salary_min.toLocaleString()} - ${jobData.salary_max.toLocaleString()}
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-xl font-semibold mb-4">Application Statistics</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="text-center">
            <div className="text-3xl font-bold text-green-600">{analytics.total_applications}</div>
            <div className="text-gray-600">Total Applications</div>
          </div>
          <div className="text-center">
            <div className="text-3xl font-bold text-blue-600">
              {analytics.unique_cities.length}
            </div>
            <div className="text-gray-600">Unique Cities</div>
          </div>
          <div className="text-center">
            <div className="text-3xl font-bold text-purple-600">
              {analytics.average_score}/10
            </div>
            <div className="text-gray-600">Average Score</div>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-xl font-semibold mb-4">Application Timeline</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={analytics.application_timeline}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Line type="monotone" dataKey="count" stroke="#8884d8" name="Applications" />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-xl font-semibold mb-4">Candidate Locations</h3>
        <CandidateMap />
      </div>
    </div>
  );

  const CandidatesTab = () => (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">City</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Score</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Application Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {analytics.top_candidates.map((candidate, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">{candidate.name}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{candidate.city}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{candidate.score}/10</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{candidate.application_date}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <RouterLink
                  to={`/candidate/${candidate.resume_id}`}
                  className="text-indigo-600 hover:text-indigo-900 flex items-center"
                >
                  View Profile
                  <ExternalLink className="w-4 h-4 ml-1" />
                </RouterLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="flex flex-col space-y-4">
      <Navbar onCreateJob={() => navigate(`/create-job`)} onChatWithAI={handleChatWithAI} />
      <div className="bg-white p-4 rounded-lg shadow">
        <nav>
          <ul className="flex space-x-4">
            <li>
              <button
                onClick={() => setActiveTab('analytics')}
                className={`py-2 px-4 rounded ${activeTab === 'analytics' ? 'bg-green-100 text-green-800' : 'text-gray-600 hover:bg-gray-100'}`}
              >
                Analytics
              </button>
            </li>
            <li>
              <button
                onClick={() => setActiveTab('candidates')}
                className={`py-2 px-4 rounded ${activeTab === 'candidates' ? 'bg-green-100 text-green-800' : 'text-gray-600 hover:bg-gray-100'}`}
              >
                Candidates
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div className="bg-gray-100 p-6 rounded-lg">
        {activeTab === 'analytics' ? <AnalyticsTab /> : <CandidatesTab />}
      </div>
    </div>
  );
};

export default JobDetail;