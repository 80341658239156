import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ArrowLeft, Send, User, Bot, PlusCircle, Briefcase, ChevronDown, ChevronUp, Users } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const AGENT_URL = process.env.REACT_APP_AGENT_URL || 'http://localhost:8001/';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/';

const ChatScreen = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [sessions, setSessions] = useState([]);
  const navigate = useNavigate();
  const { jobId, sessionId } = useParams();

  const messagesEndRef = useRef(null);
  const [currentSessionName, setCurrentSessionName] = useState('');
  const textareaRef = useRef(null);
  const eventSourceRef = useRef(null);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);

  const [jobTitle, setJobTitle] = useState('Unknown Job');
  const [showStepsMap, setShowStepsMap] = useState({});

  const [showCandidatesModal, setShowCandidatesModal] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  const fetchJobDetails = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/jobs/${jobId}`, {
        headers: { 'accept': 'application/json' }
      });
      if (response.ok) {
        const data = await response.json();
        setJobTitle(data.title);
      } else {
        console.error('Failed to fetch job details');
      }
    } catch (error) {
      console.error('Error fetching job details:', error);
    }
  }, [jobId]);

  const fetchSessions = useCallback(async () => {
    try {
      const response = await fetch(`${AGENT_URL}sessions/${jobId}`, {
        headers: { 'accept': 'application/json' }
      });
      if (response.ok) {
        const fetchedSessions = await response.json();
        setSessions(fetchedSessions);
        if (!sessionId && fetchedSessions.length > 0) {
          navigate(`/chat/${jobId}/${fetchedSessions[0].session_id}`, { replace: true });
        }
      } else {
        console.error('Failed to fetch sessions');
      }
    } catch (error) {
      console.error('Error fetching sessions:', error);
    }
  }, [jobId, navigate, sessionId]);

  const loadPastConversation = useCallback(async () => {
    try {
      const response = await fetch(`${AGENT_URL}conversation/${sessionId}?job_id=${jobId}`, {
        headers: { 'accept': 'application/json' }
      });
      if (response.ok) {
        const data = await response.json();
        const formattedMessages = data.flatMap((msg, index) => {
          const messages = [];
          
          if (msg.message_type === 'user_message') {
            messages.push({
              id: uuidv4(),
              type: 'user_input',
              content: msg.content
            });
          } else if (msg.message_type === 'planning') {
            messages.push({
              id: uuidv4(),
              type: 'thinking',
              thoughtTime: msg.total_thought,
              steps: msg.plan
            });
          } else if (msg.message_type === 'assistant_messge') {
            if (msg.content.python_code) {
              messages.push({
                id: uuidv4(),
                type: 'response',
                content: '```python\n' + msg.content.python_code + '\n```'
              });
            }
            if (msg.content.response) {
              messages.push({
                id: uuidv4(),
                type: 'response',
                content: msg.content.response
              });
            }
          }
          
          return messages;
        });
        
        setMessages(formattedMessages);
        setCurrentSessionName(sessions.find(s => s.session_id === sessionId)?.session_title || 'Chat Session');
      } else {
        console.error('Failed to load past conversation');
        setCurrentSessionName('New Chat');
        setMessages([]);
      }
    } catch (error) {
      console.error('Error loading past conversation:', error);
    }
  }, [jobId, sessionId, sessions]);

  const fetchCandidates = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/candidates_by_job_id/${jobId}`, {
        headers: { 'accept': 'application/json' }
      });
      if (response.ok) {
        const data = await response.json();
        setCandidates(data.candidates);
      } else {
        console.error('Failed to fetch candidates');
      }
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  }, [jobId]);

  useEffect(() => {
    fetchJobDetails();
    fetchSessions();
    fetchCandidates();
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, [fetchJobDetails, fetchSessions, fetchCandidates]);

  useEffect(() => {
    if (sessionId) {
      loadPastConversation();
    }
  }, [sessionId, loadPastConversation]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const setSessionTitle = async () => {
    try {
      await fetch(`${AGENT_URL}set_session_title?session_id=${sessionId}&job_id=${jobId}`, {
        method: 'POST',
        headers: { 'accept': 'application/json' }
      });
      await fetchSessions();
    } catch (error) {
      console.error('Error setting session title:', error);
    }
  };

  const renderThinking = (thinking) => {
    return (
      <div className="bg-gray-100 rounded-lg p-3 mb-2">
        <div className="text-sm text-gray-600 mb-1">
          Thought for {thinking.thoughtTime.toFixed(2)} seconds
        </div>
        <button
          onClick={() => setShowStepsMap(prev => ({ ...prev, [thinking.id]: !prev[thinking.id] }))}
          className="text-sm text-blue-500 hover:text-blue-700 focus:outline-none"
        >
          {showStepsMap[thinking.id] ? 'Hide steps' : 'View steps'}
        </button>
        {showStepsMap[thinking.id] && (
          <div className="mt-2">
            {thinking.steps.map((step, index) => (
              <div key={index} className="mb-2">
                <h4 className="font-semibold text-sm">{step.step_heading}</h4>
                <p className="text-xs text-gray-700">{step.step_detail}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderMessage = (message) => {
    switch (message.type) {
      case 'user_input':
        return (
          <div className="flex justify-end mb-4">
            <div className="bg-blue-500 text-white rounded-lg shadow-md p-4 max-w-3xl">
              {message.content}
            </div>
          </div>
        );
      case 'thinking':
        return (
          <div className="flex justify-start mb-4">
            <div className="flex items-start max-w-3xl">
              <Bot size={24} className="text-green-500 mr-2 mt-1" />
              {renderThinking(message)}
            </div>
          </div>
        );
      case 'response':
        return (
          <div className="flex justify-start mb-4">
            <div className="flex items-start max-w-3xl">
              <Bot size={24} className="text-green-500 mr-2 mt-1" />
              <div className="bg-white rounded-lg shadow-md p-4">
                <ReactMarkdown
                  className="prose prose-sm max-w-none"
                  components={{
                    code({node, inline, className, children, ...props}) {
                      const match = /language-(\w+)/.exec(className || '')
                      return !inline && match ? (
                        <SyntaxHighlighter
                          style={vscDarkPlus}
                          language={match[1]}
                          PreTag="div"
                          {...props}
                        >
                          {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      )
                    }
                  }}
                >
                  {message.content}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() !== '' && !isWaitingForResponse) {
      const candidatesContext = selectedCandidates.length > 0
        ? `\n\nContext - Selected Candidates:\n${JSON.stringify(selectedCandidates, null, 2)}`
        : '';
      const messageWithContext = inputMessage + candidatesContext;

      

      const newUserMessage = { id: uuidv4(), type: 'user_input', content: messageWithContext };
      setMessages(prev => [...prev, newUserMessage]);
      setInputMessage('');
      setIsTyping(true);
      setIsWaitingForResponse(true);
      setSelectedCandidates([])
      
      const user_profile_str = localStorage.getItem('user_profile')
      const user_profile = JSON.parse(user_profile_str)
      
      const params = new URLSearchParams({
        message: messageWithContext,
        session_id: sessionId,
        job_id: jobId,
        session_title: currentSessionName,
        job_title: jobTitle,
        user_id: user_profile['_id']
      });

      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }

      eventSourceRef.current = new EventSource(`${AGENT_URL}agent_interaction?${params.toString()}`);

      let currentThinking = { id: uuidv4(), type: 'thinking', thoughtTime: 0, steps: [] };

      eventSourceRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.type === 'complete') {
          setIsTyping(false);
          setIsWaitingForResponse(false);
          eventSourceRef.current.close();
          if (messages.filter(m => m.type === 'user_input').length % 2 === 0 && messages.length > 2) {
            setSessionTitle();
          }
        } else if (data.type === 'thinking' && data.data === 'total_seconds') {
          currentThinking.thoughtTime = data.content;
          setMessages(prev => {
            const lastMessage = prev[prev.length - 1];
            if (lastMessage.type === 'thinking') {
              return [...prev.slice(0, -1), { ...currentThinking }];
            } else {
              return [...prev, { ...currentThinking }];
            }
          });
        } else if (data.type === 'thinking' && data.data === 'step') {
          currentThinking.steps.push(data.content);
          setMessages(prev => {
            const lastMessage = prev[prev.length - 1];
            if (lastMessage.type === 'thinking') {
              return [...prev.slice(0, -1), { ...currentThinking }];
            } else {
              return [...prev, { ...currentThinking }];
            }
          });
        } else if (data.type === 'response') {
          setMessages(prev => [...prev, { id: uuidv4(), type: 'response', content: data.content }]);
        }
      };

      eventSourceRef.current.onerror = (error) => {
        console.error('EventSource failed:', error);
        eventSourceRef.current.close();
        setIsTyping(false);
        setIsWaitingForResponse(false);
        setMessages(prev => [...prev, { id: uuidv4(), type: 'response', content: "Sorry, there was an error processing your request." }]);
      };
    }
  };

  const handleNewSession = () => {
    const newSessionId = uuidv4();
    setCurrentSessionName('New Chat');
    setMessages([]);
    navigate(`/chat/${jobId}/${newSessionId}`);
  };

  const handleBackNavigation = () => {
    navigate('/jobdashboard');
  };

  const handleTextareaChange = (e) => {
    setInputMessage(e.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleViewCandidates = () => {
    setShowCandidatesModal(true);
  };

  const handleCloseModal = () => {
    setShowCandidatesModal(false);
  };

  const handleSelectCandidate = (candidate) => {
    setSelectedCandidates(prev => {
      const isSelected = prev.some(c => c.resume_id === candidate.resume_id);
      if (isSelected) {
        return prev.filter(c => c.resume_id !== candidate.resume_id);
      } else {
        return [...prev, candidate];
      }
    });
  };

  const handleConfirmCandidates = () => {
    const candidatesJson = JSON.stringify(selectedCandidates, null, 2);
    const newMessage = { 
      id: uuidv4(), 
      type: 'response', 
      content: 'Selected Candidates:\n```json\n' + candidatesJson + '\n```' 
    };
    setMessages(prev => [...prev, newMessage]);
    handleCloseModal();
  };

  const renderCandidatesModal = () => {
    if (!showCandidatesModal) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 w-3/4 max-h-3/4 overflow-y-auto">
          <h2 className="text-2xl font-bold mb-4">Candidates</h2>
          <ul>
            {candidates.map((candidate) => (
              <li
                key={candidate.resume_id}
                className={`mb-2 p-2 border rounded cursor-pointer ${
                  selectedCandidates.some(c => c.resume_id === candidate.resume_id)
                    ? 'bg-blue-100'
                    : 'hover:bg-gray-100'
                }`}
                onClick={() => handleSelectCandidate(candidate)}
              >
                {candidate.name} - Score: {candidate.score}
              </li>
            ))}
          </ul>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              onClick={handleCloseModal}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmCandidates}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              disabled={selectedCandidates.length === 0}
            >
              Confirm Selection
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-1/4 bg-white border-r border-gray-200 p-4 overflow-y-auto">
        <div className="flex items-center mb-4 text-gray-800">
          <Briefcase className="mr-2" size={20} />
          <h2 className="text-lg font-semibold truncate">{jobTitle}</h2>
        </div>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-md font-medium">Chat Sessions</h3>
          <button 
            onClick={handleNewSession}
            className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors"
          >
            <PlusCircle size={20} />
          </button>
        </div>
        {sessions.map((session) => (
          <div 
            key={session.session_id} 
            className={`p-2 hover:bg-gray-100 cursor-pointer rounded mb-2 ${sessionId === session.session_id ? 'bg-blue-100' : ''}`}
            onClick={() => navigate(`/chat/${jobId}/${session.session_id}`)}
          >
            <div className="font-semibold">{session.session_title}</div>
            <div className="text-sm text-gray-500">
              {new Date(session.last_interaction_timestamp).toLocaleString()}
            </div>
          </div>
        ))}
      </div>

      {/* Main chat area */}
      <div className="flex-1 flex flex-col">
        {/* Chat header */}
        <div className="bg-white p-4 shadow flex items-center justify-between">
          <div className="flex items-center">
            <button 
              onClick={handleBackNavigation}
              className="mr-4 p-1 rounded-full hover:bg-gray-200 transition-colors"
              aria-label="Back to Job Dashboard"
            >
              <ArrowLeft size={24} />
            </button>
            <div>
              <h1 className="text-xl font-bold">{currentSessionName}</h1>
              <p className="text-sm text-gray-500">{jobTitle}</p>
            </div>
          </div>
          <button
            onClick={handleViewCandidates}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
          >
            <Users size={20} className="mr-2" />
            View Candidates
          </button>
        </div>

        {/* Messages area */}
        <div className="flex-1 overflow-y-auto p-4 bg-gray-50">
          {messages.map((message) => renderMessage(message))}
          {isTyping && (
            <div className="flex items-center text-gray-500 mb-4">
              <Bot size={24} className="mr-2 text-green-500" />
              <span>Typing...</span>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Input area */}
        <div className="bg-white p-4 border-t border-gray-200">
          <div className="flex items-end space-x-2">
            <textarea
              ref={textareaRef}
              rows="1"
              value={inputMessage}
              onChange={handleTextareaChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey && !isWaitingForResponse) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              placeholder="Type a message..."
              className={`flex-1 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none overflow-hidden ${isWaitingForResponse ? 'bg-gray-100' : ''}`}
              style={{ minHeight: '44px', maxHeight: '120px' }}
              disabled={isWaitingForResponse}
            />
            <button
              onClick={handleSendMessage}
              className={`bg-blue-500 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors ${isWaitingForResponse ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
              disabled={isWaitingForResponse}
            >
              <Send size={20} />
            </button>
          </div>
        </div>

        {/* Candidates Modal */}
        {renderCandidatesModal()}
      </div>
    </div>
  );
};

export default ChatScreen;