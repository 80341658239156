// src/components/MessageBubble.js

import React from 'react';

const MessageBubble = ({ message }) => (
    <div className="flex items-start mb-4 w-1/2 mx-auto">
        <div className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-500 text-cyan font-bold mr-3">
            {message.sender === 'user' ? 'U' : 'B'}
        </div>
        <div>
            <div className="flex items-center mb-1">
                <span className="font-semibold text-cyan-400">{message.sender === 'user' ? 'User' : 'Blinkhire Assistant'}</span>
            </div>
            <div className={`rounded-lg p-4`}>
                {message.text}
            </div>
        </div>
    </div>
);

export default MessageBubble;