import React, { useState, useEffect } from 'react';
import { PlusIcon, Cog6ToothIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import ChatHeader from './ChatHeader';

const Spinner = () => (
  <div className="flex justify-center items-center h-full">
    <div className="loader">Loading...</div>
    {/* Add your spinner styles in your CSS */}
  </div>
);

const JobChatLayout = ({ selectedJobId, children, onLogout }) => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        
        const response = await fetch('http://localhost:8000/api/get_all_jobs', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        if (data && Array.isArray(data.records)) {
          setJobs(data.records);
        } else {
          console.error('Unexpected data format:', data);
        }
      } catch (error) {
        console.error('Error fetching jobs:', error);
        // Consider adding user-facing error handling here
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const handleJobSelect = (jobId) => {
    navigate(`/chatjob/${jobId}`);
  };

  const handleNewThread = () => {
    navigate('/chatjob/all-job');
  };

  const handleCreateJob = () => {
    navigate('/createjob');
  };

  const handleLogoutClick = () => {
    onLogout();
    navigate('/login');
  };

  const selectedJob = jobs.find(job => job.id === parseInt(selectedJobId));

  return (
    <div className="flex h-screen bg-gray-900 text-gray-300">
      {/* Left Sidebar */}
      <div className="w-64 bg-gray-800 flex flex-col">
        <div className="p-4">
          <h1 className="text-xl font-semibold text-white">Blinkhire.ai</h1>
        </div>
        <div className="p-4">
          <button
            onClick={handleCreateJob}
            className="w-full bg-gray-700 text-white px-3 py-2 rounded-md mb-4 flex items-center justify-center space-x-2 hover:bg-gray-600 transition duration-300"
          >
            <PlusIcon className="h-4 w-4" />
            <span className="text-sm">Create a Job</span>
          </button>
          <button
            onClick={handleNewThread}
            className="w-full bg-gray-700 text-white px-3 py-2 rounded-md mb-4 flex items-center justify-center space-x-2 hover:bg-gray-600 transition duration-300"
          >
            <PlusIcon className="h-4 w-4" />
            <span className="text-sm">New Thread</span>
          </button>
          <h2 className="text-xs font-semibold text-gray-400 mb-2">Job Posted</h2>
          {loading ? (
            <Spinner />
          ) : (
            <div className="space-y-2">
              {jobs.map((job) => (
                <div
                  key={job.id}
                  className={`bg-gray-700 text-white px-3 py-2 rounded-md flex justify-between items-center text-xs hover:bg-gray-600 transition duration-300 cursor-pointer ${selectedJobId == job.id ? 'ring-2 ring-cyan-400' : ''}`}
                  onClick={() => handleJobSelect(job.id)}
                >
                  <span className="truncate">{job.title}</span>
                  <span className="text-gray-400 ml-2">{job.applicants}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mt-auto p-4 border-t border-gray-700">
          <button className="w-full text-gray-300 px-3 py-2 rounded-md mb-2 flex items-center space-x-2 hover:bg-gray-700 transition duration-300 text-sm">
            <Cog6ToothIcon className="h-4 w-4" />
            <span>Settings</span>
          </button>
          <button
            onClick={handleLogoutClick}
            className="w-full text-gray-300 px-3 py-2 rounded-md flex items-center space-x-2 hover:bg-gray-700 transition duration-300 text-sm"
          >
            <ArrowRightOnRectangleIcon className="h-4 w-4" />
            <span>Logout</span>
          </button>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 flex flex-col">
        <ChatHeader job={selectedJob} isAllJobs={selectedJobId === 'all-job'} />
        {children}
      </div>
    </div>
  );
};

export default JobChatLayout;